export const QPARAMS_CHANGED = "tasks/QParamsChanged";
export const CUSTOMERS_QPARAMS_CHANGED = "tasks/CustomersQParamsChanged";
export const TASK_CUSTOMERS_QPARAMS_CHANGED = "tasks/TaskCustomersQParamsChanged";
export const SURVEYS_QPARAMS_CHANGED = "tasks/SurveysQParamsChanged";
export const _QPARAMS_CHANGED = "tasks/SurveysQParamsChanged";

export const TASK_SURVEYS = "tasksSurveys";
export const TASK_SURVEYS_LOADING = "taskSurveysLoading";

export const CUSTOMERS = "customers";
export const EQUIPMENT_ID = 'equipmentId';
export const CUSTOMERS_COUNT = "customersCount";
export const CUSTOMERS_LOADING = "customersLoading";

export const REJECTION_REASONS_LOADING = 'rejectionReasonsLoading';
export const REJECTION_REASONS = 'rejectionReasons';

export const TASK_DETAILS = 'taskDetails';
export const TASK_ORDER = 'taskOrder';
export const TASK_ORDER_LOADING = 'taskOrderLoading';

export const TASK_CATEGORIES = 'taskCategories';

export const TASK_ORIGIN = "taskOrigin";
export const TASK_ORIGIN_CODE = 0;


// CONTANCT PERSONS
export const PARTICIPANTS = 'participants';
export const CUSTOMER_CONTACT_FOR = 'customerContactsFor';
export const CUSTOMER_CONTACT_FOR_LOADING = 'customerContactsForLoading';
export const CUSTOMER_CONTACT_PERSONS = 'cusotmerContanctPersons';
export const CUSTOMER_CONTACT_PERSON_ID = 'customerPersonId';
export const CUSTOMER_CONTACT_PERSON_NAME = 'name';
export const CUSTOMER_CONTACT_PERSON_EMAIL = 'email';
export const CUSTOMER_CONTACT_PARTICIPANT_TYPE = 'participantType';

export const TASK_TYPE_CUSTOMER_DEV = [
    'CDE',
]

export const TASK_TYPE_CODES_WITH_EQUIPMENT = [
    'EPA',
    'ERD',
    'EM',
    'SCI',
    'RPL',
];

export const TASK_TYPES_WITH_PLACEMENT_AND_ACT = [
    // 'EPA',
]