import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { defaultInitialState, IDefaultState, defaultReducers } from "../../../../../redux/_common";
import { ICustomer } from "../../../../common/interfaces/Customer";
import { IRejectionReasons, ITaskDetails, ITaskOrigin, ISurveyDetails, ITaskOrderDetails } from "../interface";
import * as constants from "./constants";
import { ICustomerContactPersons, ITaskCategory } from "../../../Dashboard/_interfaces/reduxState";

interface IState extends IDefaultState<any, any, any> {
  [constants.TASK_ORIGIN]: ITaskOrigin | null,

  [constants.TASK_SURVEYS]: ISurveyDetails[];
  [constants.TASK_SURVEYS_LOADING]: boolean;

  [constants.CUSTOMERS]: ICustomer[];
  [constants.CUSTOMERS_COUNT]: number;
  [constants.CUSTOMERS_LOADING]: boolean;

  [constants.REJECTION_REASONS]: IRejectionReasons[];
  [constants.REJECTION_REASONS_LOADING]: boolean;

  [constants.TASK_DETAILS]: ITaskDetails | null;

  [constants.TASK_ORDER]: ITaskOrderDetails | null;
  [constants.TASK_ORDER_LOADING]: boolean;

  [constants.TASK_CATEGORIES]: ITaskCategory[];

  [constants.CUSTOMER_CONTACT_PERSONS]: ICustomerContactPersons[];
  [constants.CUSTOMER_CONTACT_FOR]: ICustomerContactPersons[];
  [constants.CUSTOMER_CONTACT_FOR_LOADING]: boolean;
}

const reducers = {
  ...defaultReducers,
  taskOriginFetched: (state:IState, action:PayloadAction<ITaskOrigin>) => {
    state[constants.TASK_ORIGIN] = action.payload;
  },
  resetTerritoryAssignmentCustomers: (state:any) => {
    state[constants.CUSTOMERS] = [];
  },

  setCustomersLoading: (state:IState, action:PayloadAction<boolean>) => {
    state[constants.CUSTOMERS_LOADING] = action.payload
  },
  setCustomers: (state:IState, action:PayloadAction<ICustomer[]>) => {
    state[constants.CUSTOMERS_LOADING] = false;
    state[constants.CUSTOMERS] = action.payload;
  },

  setSurveysLoading: (state:IState, action:PayloadAction<boolean>) => {
    state[constants.TASK_SURVEYS_LOADING] = action.payload
  },
  setSurveys: (state:IState, action:PayloadAction<ISurveyDetails[]>) => {
    state[constants.TASK_SURVEYS_LOADING] = false;
    state[constants.TASK_SURVEYS] = action.payload;
  },

  setRejectionReasonsLoading: (state:IState, action:PayloadAction<boolean>) => {
    state[constants.REJECTION_REASONS_LOADING] = action.payload;
  },
  setRejectionReasons: (state:IState, action:PayloadAction<IRejectionReasons[]>) => {
    state[constants.REJECTION_REASONS_LOADING] = false;
    state[constants.REJECTION_REASONS] = action.payload;
  },

  setTaskDetails: (state:IState, action:PayloadAction<ITaskDetails>) => {
    state[constants.TASK_DETAILS] = action.payload;
  },
  clearTaskDetails: (state:IState, action:PayloadAction) => {
    state[constants.TASK_DETAILS] = null;
  },
  setTaskOrderDetailsLoading: (state: IState, action: PayloadAction<boolean>) => {
    state[constants.TASK_ORDER_LOADING] = action.payload
  },
  setTaskOrderDetails: (state: IState, action: PayloadAction<ITaskOrderDetails>) => {
    state[constants.TASK_ORDER_LOADING] = false
    state[constants.TASK_ORDER] = action.payload
  },
  setTaskOrderDetailsClear: (state: IState) => {
    state[constants.TASK_ORDER_LOADING] = false
    state[constants.TASK_ORDER] = {details: [], totalSums: {totalQuantity: '', totalPrice: '',  totalStock: ''}}
  },

  setTaskCategories: (state:IState, action:PayloadAction<ITaskCategory[]>) => {
    state[constants.TASK_CATEGORIES] = action.payload;
  },

  setCustomerContactPersons: (state:IState, action:PayloadAction<ICustomerContactPersons[]>) => {
    state[constants.CUSTOMER_CONTACT_PERSONS] = action.payload;
  },

  setCustomerDropdownLoading: (state:IState, action:PayloadAction<boolean>) => {
    state[constants.CUSTOMER_CONTACT_FOR_LOADING] = action.payload;
  },
  setCustomerDropdown: (state:IState, action:PayloadAction<ICustomerContactPersons[]>) => {
    state[constants.CUSTOMER_CONTACT_FOR] = action.payload;
  }
};

const initialState:IState = {
  ...defaultInitialState,

  [constants.TASK_ORIGIN]: null,

  [constants.TASK_SURVEYS]: [],
  [constants.TASK_SURVEYS_LOADING]: false,

  [constants.CUSTOMERS]: [],
  [constants.CUSTOMERS_COUNT]: 0,
  [constants.CUSTOMERS_LOADING]: false,
  
  [constants.REJECTION_REASONS]: [],
  [constants.REJECTION_REASONS_LOADING]: false,

  [constants.TASK_DETAILS]: null,
  
  [constants.TASK_ORDER]: null,
  [constants.TASK_ORDER_LOADING]: false,

  [constants.TASK_CATEGORIES]: [],

  [constants.CUSTOMER_CONTACT_PERSONS]: [],

  [constants.CUSTOMER_CONTACT_FOR]: [],
  [constants.CUSTOMER_CONTACT_FOR_LOADING]: false,
  
};

export const tasksSlice = createSlice({
  name: "tasks",
  reducers,
  initialState,
});
