// General Info

export const GENERAL_INFO_DESCRIPTION = "description";
export const GENERAL_INFO_ENABLECOPYLAST = "enableCopyLast";
export const GENERAL_INFO_ID = "id";
export const GENERAL_INFO_ISDEFAULT = "isDefault";
export const GENERAL_INFO_ISSCORING = "isScoring";
export const GENERAL_INFO_ISSURPRESSED = "isSuppressed";
export const GENERAL_INFO_ISACTIVE = "isActive";
export const GENERAL_INFO_LANGUAGEID = "languageId";
export const GENERAL_INFO_SECTIONS = "sections";
export const GENERAL_INFO_SURVEYIDENTIFIER = "surveyIdentifier";
export const GENERAL_INFO_TASKTYPES = "taskTypes";
export const GENERAL_INFO_TIMEZONE = "timeZone";
export const GENERAL_INFO_VALIDFROMUTC = "validFromUTC";
export const GENERAL_INFO_VALIDTOUTC = "validToUTC";
export const GENERAL_INFO_VERSION = "version";

// Section Info
export const SECTIONS = "sections";
export const SECTION_INFO_ID = "id";
export const SECTION_INFO_DESCRIPTION = "description";
export const SECTION_INFO_IDENTIFIER = "surveySectionIdentifier";
export const SECTION_INFO_QUESTIONS = "questions";
export const SECTION_INFO_SEQUENCE = "sequence";
export const SECTION_INFO_PARENTID = "parentId";

// Question Info
export const QUESTIONS = "questions";
export const QUESTION_INFO_ID = "id";
export const QUESTION_INFO_SURVEYRANSWERTYPEID = "surveyAnswerTypeId";
export const QUESTION_INFO_SURVEYRANSWERSUBTYPEID = "surveyAnswerSubTypeId";
export const QUESTION_INFO_SURVEYSECTIONID = "surveySectionId";
export const QUESTION_INFO_SUBTYPE_CODE = 'subTypeCode'
export const QUESTION_INFO_TYPE_CODE = 'typeCode'

export const ANSWER_INFO_TYPE_CODE = 'surveyAnswerTypeCode';

export const QUESTION_INFO_SURVEYQUESTIONIDENTIFIER = "surveyQuestionIdentifier";
export const QUESTION_INFO_DESCRIPTION = "description";
export const QUESTION_INFO_DEFAULTVALUE = "defaultValue";
export const QUESTION_INFO_ISREQUIRED = "isRequired";
export const QUESTION_INFO_ISMODIFIED = "isModified";
export const QUESTION_INFO_TYPEDESCRIPTION = "typeDescription";
export const QUESTION_INFO_MAX = "max";
export const QUESTION_INFO_MIN = "min";
export const QUESTION_INFO_ISREADNLY = "isReadOnly";
export const QUESTION_INFO_SEQUENCE = "sequence";
export const QUESTION_INFO_COPYLASTENABLED = "copyLastEnabled";
export const QUESTION_INFO_SCORE = "score";
export const QUESTION_INFO_ISDYNAMIC = "isDynamic";
export const QUESTION_INFO_ENABLEATTACHMENT = "enableAttachment";
export const QUESTION_INFO_REQUIREATTACHMENT = "requireAttachment";
export const QUESTION_INFO_SURVEYDYNAMICQUESTIONSDEPENDENCIES = "surveyDynamicQuestionsDependencies";
export const QUESTION_INFO_SURVEYANSWERVALUES = "surveyAnswerValues";

// Survey Dynamic Questions Dependencies
export const QUESTION_DYNAMIC_DEPENDENCIES = "surveyDynamicQuestionsDependencies";
export const QUESTION_DYNAMIC_DEPENDENCIES_SURVEYCONDITIONALQUESTIONID = "surveyConditionalQuestionId";
export const QUESTION_DYNAMIC_DEPENDENCIES_SURVEYDEPENDENTANSWERVALUEID = "surveyDependantAnswerValueId";
export const QUESTION_DYNAMIC_DEPENDENCIES_SURVEYDEPENDENTQUESTIONID = "surveyDependantQuestionId";
export const QUESTION_DYNAMIC_DEPENDENCIES_SURVEYDYNAMICOPERATORID = "surveyDynamicOperatorId";
export const QUESTION_DYNAMIC_DEPENDENCIES_VALUE = "value";

// Survey Answer Values
export const QUESTION_ANSWER_VALUE = "surveyAnswerValues";
export const QUESTION_ANSWER_VALUE_ISSELECTED = "isSelected";
export const QUESTION_ANSWER_VALUE_ORDER = "order";
export const QUESTION_ANSWER_VALUE_SURVEYQUESTIONID = "surveyQuestionId";
export const QUESTION_ANSWER_VALUE_VALUE = "value";
export const QUESTION_ANSWER_VALUE_WEIGHT = "weight";
export const QUESTION_ANSWER_VALUE_ARTICLE_ID = "articleId";

//Answer types
export const EMAIL = "email";
export const DATE = "date";
export const TIME = "time";
export const NUMBER = 'number';
export const TEXT = 'text';



export const Single_code = "SNGL";
export const Multi_code = "MLTI";
export const Number_code = "NUM";
export const Time_code = "TIME";
export const Date_code = "DATE";
export const Text_code = "TXT";
export const Email_code = "EMAIL";
