export const MIN_VISIBLE_FILTERS_LENGTH = 4;

export const updateFilter = (filters:any, filter:Filter, group:string) => {
    const { id } = filter;
    const idx = filters[group].findIndex((f:Filter) => f.id === id);
    const tempArr = [...filters[group]];

    return {
        ...filters,
        [group]: tempArr.map((item:any, i:number) => {
            return idx === i ? filter : item
        })
    };
}

export const changeFilterStatus = (filter:Filter) => {
    return {
        ...filter,
        active: !filter.active ?? true
    }
}


export const updateFilters = (filters:any, updatedFilters:Filter[], group:string) => {
    let filtersGroup = [...filters[group]];

    filtersGroup = filtersGroup.map((filter:Filter) => {
        const { id } = filter;
        const updatedFilter = updatedFilters.find(f => f.id === id);

        return updatedFilter || { ...filter, active: false };
    });

    return {
        ...filters,
        [group]: filtersGroup,
    }
}

export const constructFilterQuery = (filters:any, key:string = 'title') => {
    let search = '';

    Object.entries(filters).forEach(([group, filtersGroup]:[string, any]) => {
        filtersGroup.forEach((filter:any) => {
            if (!Array.isArray(filter) && filter.active) {
                search += group + ':' + filter[key] + ','
            }
        });
    });

    return search.slice(0, search.length -1);
}

export const constructInitialFilters = (list:any[]) => {
    const constructItemFilter = (item:any, index:number) => {
      return {
        id: index,
        active: false,
        type: item.property,
        title: item.value || 'No value',
      }
    }

    let filters:any = {};
    list.forEach((filter:any, index:number) => {
      let group:any[] = filters[filter.property];
      let newFilter = constructItemFilter(filter, index);
      if (!group) {
        group = [newFilter];
      } else {
        group.push(newFilter);
      }

      filters[filter.property] = group
    });

    return filters
}